<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <v-card elevation="8">
        <v-card-title class="card-header">
          <h3 class="mb-0">
            <h3 class="mb-0">
              {{ lista_campos != "" ? "Editar" : "Criar" }} Protocolo
            </h3>
          </h3></v-card-title
        >
        <div class="card-body">
          <div class="col-md-12">
            <div class="form">
              <div class="row form-group">
                <h4 class="col-md-12">Procolos:</h4>

                <form action="">
                  <div class="row form-group col-md-12">
                    <div class="col-md-4">
                      <label class="col-md-12">Tipo Protocolo:*</label>
                      <treeselect
                        :multiple="false"
                        :options="lista_tipo_protocolo"
                        placeholder="Selecione o Tipo..."
                        v-model="form.tipo_protocolo_id"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="col-md-12">Filial:*</label>
                      <treeselect
                        :disabled="form.filial_tf != null"
                        :multiple="false"
                        :options="lista_filials"
                        placeholder="Selecione filial..."
                        v-model="form.filial_tf"
                      />
                    </div>

                    <div class="col-md-4">
                      <label class="col-md-12">Setor:*</label>
                      <treeselect
                        :multiple="false"
                        :options="lista_setor"
                        placeholder="Selecione o Setor..."
                        v-model="form.setor_id"
                        @input="input2"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="col-md-12">Contrato Servico:*</label>
                      <treeselect
                        :multiple="false"
                        :options="lista_contrato_servico"
                        placeholder="Selecione o Setor..."
                        v-model="form.contrato_servicos_id"
                        @input="input2"
                      />
                    </div>

                    <div class="col-md-3">
                      <label class="col-md-12">Data Vencimento:</label>
                      <input
                        required=""
                        type="date"
                        class="form-control"
                        v-model="form.data_vencimento"
                      />
                    </div>
                    <!-- <div class="col-md-12">
                      <label for="porcentagem">Descrição:</label>
                      <textarea
                        required=""
                        type="text"
                        class="form-control"
                        v-model="form.descricao"
                        placeholder="Digite a Descrição"
                      ></textarea>
                    </div> -->
                    <div class="col-md-12">
                      <label for="porcentagem">assunto:</label>
                      <textarea
                        required=""
                        type="text"
                        class="form-control"
                        v-model="form.assunto"
                        placeholder="Digite o Assunto"
                      ></textarea>
                    </div>

                    <InputFile text="Documentos Protocolo" ref="principal" />
                  </div>
                  <h3 v-if="lista_arqs != ''" class="text-uppercase">
                    Documentos:
                  </h3>
                  <v-sheet class="mx-auto pt-5" max-width="700">
                    <v-slide-group mandatory show-arrows>
                      <v-slide-item
                        v-for="(lista, j) in lista_arqs"
                        :key="j"
                        v-slot="{ active, toggle }"
                      >
                        <v-btn
                          class="mx-2"
                          :input-value="active"
                          active-class="success white--text"
                          depressed
                          rounded
                          @click="toggle"
                          @click.prevent="ircon(lista.id)"
                        >
                          {{ lista.nome_original }}</v-btn
                        >
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <div class="row form-group col-md-12">
                    <div class="col-md-3 pull-left">
                      <div class="md-radio-inline">
                        <label>Forma de Atendimento do protocolo:*</label>
                        <div class="space d-flex">
                          <b-form-radio
                            :inline="true"
                            :value="2"
                            v-model="form.tipo_atendimento"
                          >
                            Presencial
                          </b-form-radio>

                          <b-form-radio
                            :inline="true"
                            :value="1"
                            v-model="form.tipo_atendimento"
                          >
                            online
                          </b-form-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group col-md-12">
                    <div class="col-md-3 pull-left">
                      <div class="md-radio-inline">
                        <label>Status:*</label>
                        <div class="space d-flex">
                          <b-form-radio
                            :inline="true"
                            value="2"
                            v-model="form.status"
                          >
                            andamento
                          </b-form-radio>

                          <b-form-radio
                            :inline="true"
                            value="1"
                            v-model="form.status"
                          >
                            concluido
                          </b-form-radio>

                          <b-form-radio
                            :inline="true"
                            value="0"
                            v-model="form.status"
                          >
                            cancelado
                          </b-form-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.status == 0" class="col-md-12">
                    <label for="porcentagem">Motivo Cancelamento:</label>
                    <textarea
                      required=""
                      type="text"
                      class="form-control"
                      v-model="form.motivoCan"
                      placeholder="Digite o Motivo do cancelamento"
                    ></textarea>
                  </div>
                  <div v-if="lista_campos != ''" class="col-md-12">
                    <div
                      v-if="lista_campos.protocolo.status == 1"
                      v-show="form.status == 2"
                    >
                      <label for="porcentagem"
                        >Motivo Para Voltar em Andamento:</label
                      >
                      <textarea
                        required=""
                        type="text"
                        class="form-control"
                        v-model="form.motivoVA"
                        placeholder="Digite o Motivo do cancelamento"
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row justify-content-end">
              <div class="col-md-12 text-center">
                <v-btn
                  color="primary"
                  elevation="2"
                  :loading="verif"
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                  >{{ lista_campos == "" ? "cria" : "edita" }}</v-btn
                >
              </div>
            </div>

            <!-- </div> -->
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>
  
<script>
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import InputFile from "@/core/helpers/InputFileMult";
import JwtService from "@/core/services/jwt.service";
import filialService from "@/core/services/filial.service";

import moment from "moment";
export default {
  components: { InputFile },
  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      verif: false,
      filial: filialService.getFilialId(),

      form: {
        data_vencimento: null,
        descricao: null,
        assunto: null,
        tipo_protocolo_id: null,
        setor_id: null,
        filial_tf: null,
        arquivos_id: [],
        contrato_servicos_id: null,
        tipo_atendimento: 2,
        motivoCan: "",
        motivoVA: "",
        status: 2,
      },
      variableWithMask: "",
      arquivos: [],
      value: "",
      loading: false,
      cpf_cnpj1: null,
      saldo: null,
      host: process.env.VUE_APP_API_URL + "/arquivo/doc/",
      token: JwtService.getToken(),
    };
  },

  computed: {
    lista_contrato_servico() {
      return this.$store.state.contratoServico.lista_contrato_servico.map(
        (cat) => ({
          id: cat.id,
          label: cat.nome,
        })
      );
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    mensagem_alert() {
      return this.$store.state.protocolo.mensagem_alert;
    },
    lista_tipo_protocolo() {
      return this.$store.state.tipoProtocolo.lista_tipo_protocolo.map(
        (cat) => ({
          id: cat.id,
          label: cat.nome,
        })
      );
    },
    lista_setor() {
      return this.$store.state.setor.lista_setor.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials
        .filter((cat) => cat.id == this.filial)
        .map((cat) => ({
          id: cat.id,
          label: cat.nome,
        }));
    },
    lista_arqs() {
      return this.$store.state.arqtf.lista_arqs;
    },
  },
  created() {
    this.preenxerCampor();
  },
  mounted() {
    // Defina o valor inicial para o primeiro índice da lista_filials, se houver algum item na lista.
    if (this.lista_filials.length > 0) {
      this.form.filial_tf = this.lista_filials[0].id;
    }
  },
  watch: {},
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Tem Certeza?",
        message:
          `Esta ação  ` + tipo + ` um protocolo para o cliente no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      if (this.$refs.principal.file.length > 0) {
        // console.log(this.$refs.principal)
        // console.log(this.$refs.principal.file)
        this.verif = true;
        this.arquivos = await this.$refs.principal.submit();
        this.form.arquivos_id = this.arquivos;
      }
      // this.form.arquivo_id = this.arquivo_id;

      await this.$store.dispatch("protocolo/create_protocolo", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "dashboard",
      });
    },
    // onInput(value) {
    //   let usuarios = Object.values(
    //     this.$store.state.configUsuarios.lista_usuarios
    //   );
    //   usuarios = usuarios.filter((use) => use.id === value);
    //   console.log(usuarios[0]);
    //   this.cpf_cnpj1 = usuarios[0].cpf;
    // },
    async update() {
      this.verif = true;
      await this.$store.dispatch("protocolo/update_protocolo", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "dashboard",
      });
    },
    // input(item) {
    //   this.$store.dispatch("setor/listar_setor_filial", item);
    // },

    // input2(item) {
    //   this.$store.dispatch("setor/listar_usuarios", item);
    // },

    async preenxerCampor() {
      await this.$store.dispatch("setor/listar_setor");

      // console.log(this.$store.state.sistema.lista_filiais_autorizada);
      if (this.lista_campos != "") {
        var protocolo = this.lista_campos.protocolo;
        await this.$store.dispatch("arqtf/listar_arqs", protocolo.id);

        this.form = {
          id: protocolo.id,
          data_vencimento: moment(protocolo.data_vencimento).format(
            "YYYY-MM-DD"
          ),
          descricao: protocolo.descricao,
          assunto: protocolo.assunto,
          tipo_protocolo_id: protocolo.tipo_protocolo_id,
          status: protocolo.status,
          contrato_servicos_id: protocolo.contrato_servicos_id,
          filial_tf: this.lista_campos.filial_tf,
          tipo_atendimento: protocolo.tipo_atendimento,
          motivoCan: protocolo.motivoCan,
          motivoVA: protocolo.motivoVA,

          setor_id: this.lista_campos.setor.id,
        };
      } else {
        //  this.$router.push({ name: "dashboard" });
      }
      await this.$store.dispatch("tipoProtocolo/listar_tipo_protocolo");
      await this.$store.dispatch("configEmpresa/listar_filial");
      await this.$store.dispatch("contratoServico/listar_contrato_servico");
      this.$store.dispatch("setor/listar_setor");
    },
    ircon(value) {
      window.open(this.host + value + "?token=" + this.token, "_blank");
    },
  },
};
</script>
  
  <style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>